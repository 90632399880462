<template>
    <v-container>
        <v-dialog width="500" v-model="eventDialogVisible">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    {{ d.editEvent }}
                </v-card-title>
                <v-card-text>
                    <!-- <v-text-field v-model="eventEdited.data.date" :label="d.date"></v-text-field> -->
                    <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="eventEdited.data.date" :label="d.date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="eventEdited.data.date" @input="menu2 = false"></v-date-picker>
                    </v-menu>
                    <v-text-field v-model="eventEdited.data.dateTxt" label="Date as text"></v-text-field>
                    <v-text-field v-model="eventEdited.data.time" :label="d.time"></v-text-field>
                    
                    <v-text-field v-model="eventEdited.data.title" :label="d.name"></v-text-field>
                    <v-textarea outlined rows="1" auto-grow v-model="eventEdited.data.description_it" :label="d.description+' italiano'"></v-textarea>
                    <v-textarea outlined rows="1" auto-grow v-model="eventEdited.data.description_en" :label="d.description+' english'">Inglese</v-textarea>
                    <v-textarea outlined rows="1" auto-grow v-model="eventEdited.data.description_nl" :label="d.description+' nederlands'">Inglese</v-textarea>
                    <v-textarea outlined rows="1" auto-grow v-model="eventEdited.data.description_de" :label="d.description+' deutsch'">Inglese</v-textarea>
                    <v-text-field v-model="eventEdited.data.location" :label="d.location"></v-text-field>
                    <v-text-field v-model="eventEdited.data.price" :label="d.price"></v-text-field>
                    <v-text-field v-model="eventEdited.data.contact" :label="d.contact"></v-text-field>
                    <input type="file" style="display: block;visibility: hidden;width: 0;height: 0;" @change="eventImageAdd" ref="eventImg" /><v-icon @click="eventImageAddTrigger">mdi-image-plus</v-icon><div style="display:inline-block">{{ eventEdited.data.eventImage }}</div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT PAGE CONTENT-->
        <v-card>
            <v-data-table multi-sort search :headers="headers" :items="events" @click:row="eventEdit"></v-data-table>
            <v-btn dark fab half-way color="primary" @click="eventAdd ()"><v-icon>mdi-plus</v-icon></v-btn>
        </v-card>
    </v-container>    
</template>

<script>
import { collection, doc, getDocs, getFirestore, setDoc, updateDoc } from "firebase/firestore"; //collection, getDoc, getDocs, doc, setDoc 
import firebaseApp from '../../firebase/init';
let db = getFirestore( firebaseApp );
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage();
export default {
    name: 'CalendarUser',
    data () { 
        return {
            eventDialogVisible: false,
            eventEdited: { data: {}, id: null },
            events: [],
            headers: [ 
                // {   
                //     text: 'Id',
                //     value: 'id'

                // },
                {
                    text: 'Data',
                    align: 'start',
                    value: 'data.date',
                },
                { text: 'Title', value: 'data.title' },
                {
                    text: 'Descrizione',
                    value: 'data.description_it'
                }
            ], 
            menu2: false,
        }
    },
    computed: {
        d () {
			return this.$store.getters.lang_dict
		},
        dbRef () {
            // return db.collection('Visitgradoli').doc('MenuStructure').collection('Activities').doc('Calendar').collection('Events')
            return 'Visitgradoli/MenuStructure/Activities/Calendar/Events/'
        },
    },
    async created () {
        // get events from database
        getDocs ( collection ( db, this.dbRef ))
        .then(snapshot => {
            snapshot.forEach(doc => {
                let eventLocal = {};
                eventLocal.data = doc.data().data;
                eventLocal.id = doc.id
                this.events.push(eventLocal)
            });
            
        })  
    },
    methods: {
        async getImageUrl ( fileName ) { 
            const partner = this.$store.state.partner[0];
            // get the image url for the asset
            let imageRef = partner+'/'+this.$store.state.currCustomer+'/'+this.$store.state.currSite+'/'+fileName;
            let storageRef = ref ( storage, imageRef );
            // let storageRef = firebase.storage().ref(imageRef)
            let url = await getDownloadURL( storageRef );
            console.log(url);
            return url;
        },
        async eventAdd () {
            console.log("method eventAdd");
            let newEventRef = doc ( collection ( db, this.dbRef ) )
            console.log( newEventRef.id )
            await setDoc ( newEventRef ,
                {
                    id: newEventRef.id,
                    data: {
                        title: 'New event'
                    }
                }
            )
            console.log(newEventRef)
            this.events.push( { id: newEventRef.id, data: { title: 'New event' } } );
            this.eventEdit ( this.events[ this.events.length-1 ] );
        },
        async eventImageAdd (event) {
            console.log('method eventImageAdd')
            let file = event.target.files[0];
            // Make a copy of the file so that the name can be changed???
            var blob = file.slice(0, file.size, 'image/jpeg')
            const newFile = new File([blob], file.name, {type: 'image/jpeg'})
            // In case of an iPad all foto's have the name image.jpg
            let storedFile = await this.$store.dispatch('fileUpload', newFile); // returns object with name and url properties
            console.log(storedFile);
            // set filename to data
            this.eventEdited.data.eventImage = storedFile.fileName;
            console.log(this.eventEdited.data.eventImage)
            this.eventSave( this.eventEdited );
            // END OF METHOD
        },
        eventImageAddTrigger () {
            var elem = this.$refs.eventImg;
            elem.value = null;
            elem.click();
        },
        eventEdit ( event ) {
            console.log(event)
            this.eventEdited = event;
            this.eventDialogVisible = true;
        },
        eventSave ( event ) {
            // console.log( event )
            updateDoc ( doc ( db, this.dbRef + event.id ), {
                data: event.data 
            })
        },
    }, 
    watch: {
        eventDialogVisible: function ( newValue ) {
            if(!newValue){
                this.eventSave ( this.eventEdited );
            }
        }
    }
}

</script>

<style>
table {
    border: none
}
th {
    color: white;
    background-color: blue;
}
th, td {
    text-align: left;
    padding-right: 10px
}
</style>